:root{
  --color-text: #616161;
  --color-text-btn: #ffffff;
  --card1-gradient-color1: #f12711;
  --card1-gradient-color2: #f5af19;
  --card2-gradient-color1: #7F00FF;
  --card2-gradient-color2: #E100FF;
  --card3-gradient-color1: #3f2b96;
  --card3-gradient-color2: #a8c0ff;
  --card4-gradient-color1: #11998e;
  --card4-gradient-color2: #38ef7d;
}

body{
  background-color: #14161f !important;
}
.navbar-collapse:before {
    background: #639ec9;
    background: linear-gradient(#1b7198, #012335 70%) !important;
}
.input-group .input-group-prepend .input-group-text, .form-group .input-group-prepend .input-group-text {
    border-left: 1px solid #E3E3E3;
    background-color: #ededed !important;
}
.input-lg.input-group::focus{
    border-radius: 20px solid black !important;

}
.description, .card-description, .footer-big p {
    color: #3d3955da;
    font-weight: 500;
}
p{
    font-weight: 400;
}
.form-control:focus{
    border-radius: 5px solid blue !important;
}

.footer{
    background-color: #07353D !important;
    padding: 0 !important;
}
  
  .form-control::placeholder {
    font-size: 0.95rem;
    color: #0b0d17;
    font-style: italic;
  }
  
  .form-control.shadow-0:focus {
    box-shadow: none;
  }
  .font-weight-bold {
    font-weight: 600!important;
    color:#dadada !important;
}
hr {
    border: 0;
    clear:both;
    display:block;
    width: 100%; 
    margin-left: 0;
       
    background-color:#1e646c;
    height: 1px;
  }
  .text-muted {
    color: #2c511dda!important;
}

#social{
    color: #dadada !important;
   
    font-size: 20px;
}
#social:hover{
    color:  #27a3d9 !important; 
    cursor: pointer;

}
@media (min-width: 992px){
.col-lg-4 {
    flex: 0 0 41%;
    max-width: 42% !important;
}
}
.bg-light {
    background-color: #ededed !important;
}
@media screen and (max-width: 991px){
    .sidebar-collapse .navbar-collapse {
        background-color: #0b0d17 !important;
    }}

    .btn-info {
        background-color: #3867af;
        color: #ededed;
    }
    .section-story-overview p.blockquote {
        width: 300px;
        min-height: 180px;
        text-align: left;
        position: absolute;
        padding: 5px !important;
        top: 70px;
        right: 210px;
        z-index: 0;
    }
    .blockquote.blockquote-info {
        border: 2px solid #9d914400;
        
        color: #9d9144;
    }
    .blockquote small{
      text-transform: none;
      color:#516eab !important;
    }

    /***********************************cards*/

    
.snip1336 {
    position: relative;
    overflow: hidden;
    margin: 10px;
    border-radius: 20px;
    min-width: 230px;
    max-width: 315px;
    width: 100%;
    color: #ffffff;
    text-align: left;
    line-height: 1.4em;
    background-color: #dadada;
  }
  .snip1336 * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  .snip1336 img {
    max-width: 100%;
    vertical-align: top;
    opacity: 0.85;
  }
  .snip1336 figcaption {
    width: 100%;
    background-color: #dadada;
    padding: 25px;
    position: relative;
  }
  .snip1336 figcaption:before {
    position: absolute;
    content: '';
    bottom: 100%;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 55px 0 0 400px;
    border-color: transparent transparent transparent #dadada;
  }
  .snip1336 figcaption a {
    padding: 5px;
    border: 1px solid #ffffff;
    color: #ffffff;
    font-size: 0.7em;
    text-transform: uppercase;
    margin: 10px 0;
    display: inline-block;
    opacity: 0.65;
    width: 47%;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .snip1336 figcaption a:hover {
    opacity: 1;
  }
  .snip1336 .profile {
    border-radius: 50%;
    position: absolute;
    bottom: 95%;
    left: 25px;
    z-index: 1;
    height: 100px;
    width: 100px;
    background-color: #dadada;
    opacity: 1;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  }
  .snip1336 .follow {
    margin-right: 4%;
    border-color: #2980b9;
    color: #2980b9;
  }
  .snip1336 h2 {
    margin: 0 0 5px;
    font-weight: 300;
  }
  .snip1336 h2 span {
    display: block;
    font-size: 0.5em;
    color: #2980b9;
  }
  .snip1336 p {
    margin: 0 0 10px;
    font-size: 0.8em;
    letter-spacing: 1px;
    opacity: 0.8;
  }
  #fixabout .container-xs{
    max-width: 100% !important;
  }
  h2.mt-0.mb-0 {
    font-weight: 500 !important;
  }

  /****************************************************/

.tiles-wrap {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
    
}

@media screen and (min-width: 1200px){
    .tiles-wrap {
        margin-left: 60px !important;

    }}

   #fasicon {
      font-size: 50px;
      margin-left: 20px;
      margin-top: 25px;
      color: #265970;
  }

  @media screen and (max-width: 400px){
    #header{
      font-size: 25px;
    }
  }

  .container3{
    margin-top: 30px;
  }
  
  .wrapper3{
    text-align: center;
  }
  
  svg:nth-child(1) .pulse-disk {
    fill: #22b5e2;
    stroke-width: 4px;
    
  }
  
  svg:nth-child(1) .pulse-circle,
  svg:nth-child(1) .pulse-circle-2 {
    stroke: #22b5e29c;
    stroke-width: 4px;
    
  }
  
  .pulse-circle,
  .pulse-circle-2 {
    transform-origin: center;
    stroke-width: 2;
    fill: none;
    transform: translate3d(0, 0, 0);
  }
  

  @mixin circle($circleSize){
    width: $circleSize;
    height: $circleSize;
    -webkit-border-radius: $circleSize/2;
       -moz-border-radius: $circleSize/2;
            border-radius: $circleSize/2;
  }
  
  .pulse-box{
    float: left;
    width: 50%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* pulse in SVG */
  svg#pulse-svg{
    overflow: visible;
    .first-circle{
      fill: rgb(9, 169, 197);
      transform: scale(0.5);
      transform-origin: center center;
      animation: pulse-me 3s linear infinite;
    }
    .second-circle{
      @extend .first-circle;
      animation-delay: 1s;
    }
    .third-circle{
      @extend .first-circle;
      animation-delay: 2s;
    }
  }
  
  /* pulse in CSS */
  .pulse-css{
    @include circle(50px);
    
    position: relative;
    &:before,
    &:after{
      content: '';
      @include circle(50px);
      background-color: rgb(14, 145, 185);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      transform: scale(0.5);
      transform-origin: center center;
      animation: pulse-me 5s linear infinite;
    }
    &:after{
      animation-delay: 2s;
    }
  }
  
  @keyframes pulse-me{
    0%{
      transform: scale(0.5);
      opacity: 0;
    }
    50%{
      opacity: 0.4;
    }
    70%{
      opacity: 0.2;
    }
    100%{
      transform: scale(5);
      opacity: 0;
    }
  }
  #pulse-svg{
    fill:rgb(20, 150, 197);
  }
  .image-container.image-left {
    box-shadow: none;
}


.item {background-color:#ffffff; width:250px;margin-top:80px;margin-left: auto;
  margin-right: auto;
  border-top-right-radius: 30px;border-bottom-left-radius: 30px;}
.item .image {overflow:hidden; border-top-right-radius: 30px;}
.item .image img { width:100%; }

.item .text {padding:2em; text-align:center;}
.item .text figcaption {position:relative; z-index:1; }
.item .text a {color:rgba(0,0,0,0.70);}
.item .text p {
  color:rgba(0,0,0,0.70); 
  line-height:22px; 
  font-weight:300;
  font-size:13px;
}
.item .text .fa {
  font-size:18px;
  width:15px;
  height:15px;
  padding:13px;
  border:solid 1px rgba(0,0,0,0.8);
  border-radius:50%;
}

/* ---- Responsive Code ---- */
@media (max-width: 768px) {
  .item {margin-top: 10px;}
  .col-md-6 {
    max-height: 430px;
  }

}

img#greece {
  width: 49px;
  position: absolute;
  top: 50%;
  left: -2%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.reload {
	position:absolute;
	right:30px;
	top:30px;
	font-size:25px;
	cursor:pointer;
}

.reload i {
	transition:color ease 0.5s;
	color:rgba(198, 73, 73, 0.1);
}

.reload:hover i {
	transform:rotate(360deg);
	transition: all ease 0.5s;
	color:rgba(126, 10, 10, 0.15);
}

.signature {
  width: 100%;
  margin-top: -20px;
  float: right;
  max-width: 240px;
  box-sizing: border-box;
  border-bottom-left-radius: auto;
  position: relative;
}

.signature::before {
	content: "";
	background: #ededed;
	width: 70%;
	height: 1px;
	position: absolute;
	bottom: 45%;
	left: 11%;
	z-index:0;
}



@keyframes draw {
	0% {
		stroke-dashoffset: 2500px;
	}
	
	100% {
		stroke-dashoffset: 0px;
	}
}
h6, .h6 {
  font-size: 0.9em !important;
}

img#backimg {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  
}

/************************************/

header#head{
  min-height: 100vh;
  position: relative;
  .containernew{
      max-width: 1368px;
      margin: 0 auto;
      padding: 0rem 2rem;
      .navbar{
          min-height: 100vh;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .logo{
              font-size: 2.25rem;
              text-decoration: none;
              text-transform: uppercase;
              color: #fff;
              font-weight: 700;
          }
          .nav-links{
              display: flex;
              align-items: center;
              li{
                  list-style-type: none;
                  a{
                      text-decoration: none;
                      color: #fff;
                      margin: 0 1.25rem;
                      font-weight: 500;
                      font-size: 1.05rem;
                      position: relative;
                      &::after{
                          position: absolute;
                          content: '';
                          background-color: #fff;
                          bottom: -5px;
                          left: 0;
                          width: 0%;
                          height: 3px;
                          transition: 0.3s ease all;
                      }
                      &:hover::after{
                          width: 100%;
                      }
                  }
              }
              button{
                  border: none;
                  outline: none;
                  cursor: pointer;
                  padding: 0.75rem 1rem;
                  margin: 0 1rem;
                  border-radius: 6px;
                  background: transparent;
                  border: 2px solid #fff;
                  color: #fff;
                  font-weight: 500;
              }
          }
          i{
              display: none;
              color: #fff;
              cursor: pointer;
          }
      }
      .showcase{
          min-height: 100vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          width: 35rem;
          h1{
              color: #fff;
              font-size: 2.5rem;
              margin-bottom: 0;
              font-weight: 700;
              margin-bottom: 1rem;
          }
          p{
              margin-top: 0;
              color: #fff;
              font-weight: 500;
              line-height: 1.4;
              font-size: 0.9rem;
              
          }
          button{
              margin-top: 1rem;
              background-color: #000;
              color: #fff;
              border: none;
              outline: none;
              padding: 1rem 1.5rem;
              font-size: 0.9rem;
              border-radius: 6px;
              cursor: pointer;
          }
      }
  }
  .video-container{
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      video{
          height: 100%;
          width: 100%;
          object-fit: cover;
      }
      &::after{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          
      }
  }
}

@media(max-width: 850px){
  header#head{
      .containernew{
          .navbar{
              .nav-links{
                  position: absolute;
                  top: 0;
                  left: 0;
                  background-color: #fff;
                  height: 100%;
                  width: 225px;
                  margin: 0;
                  padding: 0;
                  flex-direction: column;
                  justify-content: space-evenly;
                  align-items: center;
                  transform: translateX(-100%);
                  transition: 0.4s ease all;
                  z-index: 10;
                  li{
                      a{
                          color: #000;
                          &::after{
                              background-color: #000;
                          }
                      }
                  }
                  button{
                      border-color: #000;
                      color: #000;
                      padding: 0.75rem 1.5rem;
                  }
                  &.active{
                      transform: translateX(0%);
                  }
              }
              i{
                  display: block;
              }
          }
          .showcase{
              min-height: 80vh;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 100%;
              text-align: center;
              h1{
                  font-size: 2.25rem;
              }
          }
      }
  }   
}
@media(min-width: 500px){
  #pfooter{
    margin-right: 50px;
    text-align: justify !important;
  }
}
@media(max-width: 500px){

  #titleh2{
    font-size: 25px !important;
    text-align: none !important;
  }
  #pfooter{
    margin:0 !important;
  }
  header{
      .containernew{
          .showcase{
            h1{
              font-size: 2.1rem;
            }
            p{
              font-size: 0.85rem;
              
            }
          }
      }
  }   
}
.page-header:before {
background-color: rgba(0, 0, 0, 0) !important;
}
.page-header:after {
  background-color: rgba(0, 0, 0, 0) !important;
  }
  .header#head{background-color: rgba(0, 0, 0, 0) !important;}

  header#head .video-container{
    filter: brightness(60%);
    
  }
  
  
  .home-main__split {
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  
  .home-main__split--left {
    background: url("https://i.ibb.co/FHFDB8Q/bg-left.jpg") var(--split-left) no-repeat
      center / cover;
    justify-content: flex-end;
  }
  
  .home-main__small-text {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 0.85em;
    position: absolute;
    bottom: 11%;
    left: 0%;
    transform: rotate(-90deg);
  }
  .home-main__small-text::before {
    content: "";
    position: absolute;
    border: 1px solid rgb(220, 220, 220);
    width: 40px;
    top: 9px;
    left: -50%;
  }

  ul#ul2 {
    padding-left: 0.5em;
    list-style: none;
    margin: 1.5em 0 2em 0;
  }
  li#list {
    margin: -0.5em 0;
    text-align: justify;
    padding: 0px 0px 20px 0px;
    font-size: 1.2em;
    color: #e1e1e1;
  }
  li#list::before {
    content: "";
    display: inline-block;
    transform: rotate(45deg);
    height: 0.8em;
    width: 0.43em;
    border-bottom: 0.15em solid;
    border-right: 0.15em solid;
    margin-right: 1em;
  }
  @media (min-width: 768px){
  .col-md-8 {
      flex: 0 0 80%;
      max-width: 80%;
  }

  .col-md-5 {
    flex: 0 0 40%;
    max-width: 40%;
}
#contact.col-md-8 {
  flex: 0 0 50%;
  max-width: 50%;
}
}
input:-internal-autofill-selected {
  
  background-color: white !important;
}
#a:hover {color: #8da7de !important}
#a {  text-decoration: none !important;}
@media (max-width: 968px){
.btn, .navbar .navbar-nav > a.btn {
  /* border-width: 2px; */
  font-weight: 400;
  font-size: 0.8571em;
  line-height: 1.35em;
  margin: 0px;
  border: none;
  border-radius: 0.1875rem;
  padding: 15px 26px;
  cursor: pointer;
  background-color: #f4f4f400;
  color: #ffffff;
}
.btn:hover, .btn:focus, .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus, .btn:active:hover, .btn.active:hover, .show > .btn.dropdown-toggle, .show > .btn.dropdown-toggle:focus, .show > .btn.dropdown-toggle:hover, .navbar .navbar-nav > a.btn:hover, .navbar .navbar-nav > a.btn:focus, .navbar .navbar-nav > a.btn:not(:disabled):not(.disabled):active, .navbar .navbar-nav > a.btn:not(:disabled):not(.disabled).active, .navbar .navbar-nav > a.btn:not(:disabled):not(.disabled):active:focus, .navbar .navbar-nav > a.btn:not(:disabled):not(.disabled).active:focus, .navbar .navbar-nav > a.btn:active:hover, .navbar .navbar-nav > a.btn.active:hover, .show > .navbar .navbar-nav > a.btn.dropdown-toggle, .show > .navbar .navbar-nav > a.btn.dropdown-toggle:focus, .show > .navbar .navbar-nav > a.btn.dropdown-toggle:hover {
  background-color: #d4d4d463;
  color: #e3e3e3;
  box-shadow: none;
}
}
@media (min-width: 968px){
  .btn, .navbar .navbar-nav > a.btn {
    /* border-width: 2px; */
    font-weight: 400;
    font-size: 0.8571em;
    line-height: 1.35em;
    margin: 0px;
    border: none;
    border-radius: 0.1875rem;
    padding: 13px 14px;
    cursor: pointer;
    background-color: #f4f4f400;
    color: #07353d;
  }
  .navbar a:not(.btn):not(.dropdown-item) {
    color: #07353d !important;
}
.navbar a:not(.btn):not(.dropdown-item):hover {
  color: #1da4b2 !important;
}
.btn:hover, .btn:focus, .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus, .btn:active:hover, .btn.active:hover, .show > .btn.dropdown-toggle, .show > .btn.dropdown-toggle:focus, .show > .btn.dropdown-toggle:hover, .navbar .navbar-nav > a.btn:hover, .navbar .navbar-nav > a.btn:focus, .navbar .navbar-nav > a.btn:not(:disabled):not(.disabled):active, .navbar .navbar-nav > a.btn:not(:disabled):not(.disabled).active, .navbar .navbar-nav > a.btn:not(:disabled):not(.disabled):active:focus, .navbar .navbar-nav > a.btn:not(:disabled):not(.disabled).active:focus, .navbar .navbar-nav > a.btn:active:hover, .navbar .navbar-nav > a.btn.active:hover, .show > .navbar .navbar-nav > a.btn.dropdown-toggle, .show > .navbar .navbar-nav > a.btn.dropdown-toggle:focus, .show > .navbar .navbar-nav > a.btn.dropdown-toggle:hover {
  background-color: #d4d4d463;
  color: #1da4b2;
  box-shadow: none;
}
  }
  
  .square-holder {
    padding:30px;
    border: 1px solid #cecece;
    align-items: center;
    display:flex;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom:20px;
    background-color:#f1f1f1;
    min-height:200px
  }
  
  .square-holder img {
    max-width:100%;
    filter: grayscale(100%);
    transition: all 0.3s;
  }
  
  .square-holder:hover img{
    filter:none;
    cursor:pointer;
  }
  .square-holder:hover {
    background-color:#f7f7f7;
 }
 a#linkz{
  color:#1da4b2
  
 }
 a#linkz:hover{
  color:#07353d;
  cursor: pointer;
 }
 .modal.show .modal-dialog {
  transform: none;
  color: #07353d;
}
p.card-text {
  text-align: center;
}

.card-wrap {
  width: 280px;
  display: inline-block;
  min-height: 360px;
  margin: 14px;
  background: #f3f3f3;
  padding: 20p;
  border-radius: 20px;
  border: 5px solid #f3f3f3;
  overflow: hidden;
  color: var(--color-text);
  box-shadow: rgb(0 0 0 / 19%) 0px 10px 20px, rgb(0 0 0 / 23%) 0px 6px 6px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.card-wrap:hover{
  transform: scale(1.1);
}
.card-header{
  height: 100px;
  width: 100px;
  background: red;
  border-bottom-right-radius: 70% !important;
  display: grid;
  place-items: center;

}

.card-header i{
  color: #fff;
  font-size: 72px;
}
.card-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}
.card-title{
  text-align: center;
  font-size: 16px;
  color:#07353D;
  margin-top: 10px;
  font-weight: 500;
  margin-bottom: 20px;
  
}
.card-text{
  text-align: left;
  font-size: 12px;
  margin-bottom: 20px;
}
.card-btn{
  border: none;
  border-radius: 100px;
  padding: 5px 30px;
  color: #fff;
  margin-bottom: 15px;
  
}
.card-header.one{
  background: linear-gradient(90deg, #022234 0, #0f9cdc) !important;
}
.card-header.two{
  background: linear-gradient(90deg, #022234 0, #0f9cdc) !important;
}
.card-header.three{
  background: linear-gradient(90deg, #022234 0, #0f9cdc) !important;
}
.card-header.four{
  background: linear-gradient(90deg, #022234 0, #0f9cdc) !important;
}

.card-btn.one{
  background: linear-gradient(to left, var(--card1-gradient-color1), var(--card1-gradient-color2));
}
.card-btn.two{
  background: linear-gradient(to left, var(--card2-gradient-color1), var(--card2-gradient-color2));
}
.card-btn.three{
  background: linear-gradient(to left, var(--card3-gradient-color1), var(--card3-gradient-color2));
}
.card-btn.four{
  background: linear-gradient(to left, var(--card4-gradient-color1), var(--card4-gradient-color2));
}
#cardz{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}
#ulu.li{
  font-size: 13px;
}
#globe:hover{
  color:#dadada !important;
}
#btnc{
  background-color: #f4f4f4 !important;
  color: #1e646c !important;
}
#btnc:hover{
  background-color: #1e646c !important;
  color: whitesmoke !important;
}
#typ{
  color:#07353D !important;
  font-weight: 600;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root{
  background-color: #fff0 !important;
  box-shadow: none !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  position: absolute;
  left: 0;
  top: -1px;
  right: 0;
  height: 1px;
  content: "";
  opacity: 1;
  background-color: #dadada;
}
.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  color: #1da4b2 !important;
}
#cord{
  color: #1da4b2 !important;
}
#cord:hover{ 
  color: #dadada !important;
}


div#rect {
  background-color: #eaeaea;
  border-radius: 20px;
  height: 180px;
  min-width: 300px;
  max-width: 500px;
  margin: auto;
}





element.style {
  background: white;
}

button#btnc {
  background: #f4f4f4 !important;
    color: #1e646c !important;
}
#tet{
  padding: none;
  border-radius: none;
  background: none;
  

  border: none;
  outline: none;
  margin-left:none;
  background: none
}
#tet:hover{
  background: #ffffff3f
}
button.navbar-toggler.navbar-toggler {
  background: none;
}
button.dropdown-item{
  margin:0 !important;
  border-radius: 0;
  background-color: none !important;
}
button.dropdown-item:hover{
  background-color: #ffffff4a !important;
}
a.nav-link {
  font-weight: bold !important;
}
.bg-light{
  background-color: #012335 !important;
}

.styles_lightroom__1X2qE{
  top:94px !important;
  height:92% !important;
}


.container3 {
  flex: 0 1 700px;
  margin: auto;
  padding: 10px;
  max-width: 600px;
}

.screen {
  position: relative;
  background: #0b3854;
  border-radius: 15px;
}

.screen:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  bottom: 0;
  border-radius: 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, .4);
  z-index: -1;
}

.screen-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background: #4d4d4f;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.screen-header-left {
  margin-right: auto;
}

.screen-header-button {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 3px;
  border-radius: 8px;
  background: white;
}

.screen-header-button.close {
  background: #27a3d9;
}

.screen-header-button.maximize {
  background: #dadada;
}

.screen-header-button.minimize {
  background: #27a3d9;
}

.screen-header-right {
  display: flex;
}

.screen-header-ellipsis {
  width: 3px;
  height: 3px;
  margin-left: 2px;
  border-radius: 8px;
  background: white;
}

.screen-body {
  display: flex;
}

.screen-body-item {
  flex: 1;
  padding: 50px;
}

.screen-body-item.left {
  display: flex;
  flex-direction: column;
}

.app-title {
  display: flex;
  flex-direction: column;
  position: relative;
  color: #ededed;
  font-size: 26px;
}

.app-title:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 25px;
  height: 4px;
  background: #27a3d9;
}

.app-contact {
  margin-top: auto;
  font-size: 8px;
  color: white;
}

.app-form-group {
  margin-bottom: 15px;
}

.app-form-group.message {
  margin-top: 40px;
}

.app-form-group.buttons {
  margin-bottom: 0;
  text-align: right;
}

.app-form-control {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #27a3d9b8;
  color: #ddd;
  font-size: 14px;
  text-transform: uppercase;
  outline: none;
  transition: border-color .2s;
}

.app-form-control::placeholder {
  color: #27a3d9b8;
}

.app-form-control:focus {
  border-bottom-color: #ddd;
}

.app-form-button {
  background: none;
  border: none;
  border: 1px solid whitesmoke;
  border-radius: 10px;
  color: #dadada;
  font-size: 14px;
  cursor: pointer;
  outline: none;
}

.app-form-button:hover {
  color: #ededed;
  background-color: #0e7cae;
}

.credits {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #ffa4bd;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  font-weight: normal;
}

.credits-link {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

.dribbble {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}

@media screen and (max-width: 520px) {
  .screen-body {
    flex-direction: column;
  }
.container3{
  max-width: 90%;
}
  .screen-body-item.left {
    margin-bottom: 30px;
  }

  .app-title {
    flex-direction: row;
  }

  .app-title span {
    margin-right: 12px;
  }

  .app-title:after {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .screen-body {
    padding: 40px;
  }

  .screen-body-item {
    padding: 0;
  }
}
.screen-body {
    background-color: #0b3854;
    border-radius:0px 0px 20px 20px;
}
.screen-header {
  background-color: #0a2e48;
}

#consortium{
  background: linear-gradient(90deg, #012335 0, #1b7198) !important;
}
span{
  text-align: left;
}
ul{
  font-size: 15px;
}
li{
  padding: 6px;
}
img#espa {
  max-width: 520px;
  position: absolute;
  float:right;
  bottom: 22vh;
  left: 0;
  width: 70%;
}
ul li#social{
  display: inline;
}
ul {
  font-size: 15px;
  position: relative;
  left: -10px;
}
#bold{
  font-weight: 500;
}
.grid_wrapper_2_img {
  grid-template-columns: repeat(4, 1fr) !important;
}
@media (min-width: 1200px) {
  .container {
      max-width: 80% !important;
  }
}
button#rcc-confirm-button {
  margin-top: 0px !important;

}


select {
  font-weight: 600;
  font-size: 14px;
  color: #07353d;
  background-color: #0000ff00;
  border: none;
  margin-top: 7%;
}
.custom-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-btn {
  border: none;
  background-color: #ededed00 !important;
  padding: 4px;
  font-weight: 600;
  color: #07353d !important;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.custom-dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content button {
  background: none;
  border: none;
  padding: 10px;
  width: 100%;
  font-size: 12px;
  cursor: pointer;
  text-align: left;
}

.dropdown-content button:hover {
}